import React from 'react';
import Day from '../day';
import './week.css';

const Week = (props) => {
    let days = [];
    let {date} = props;
    const {month, selected, select} = props;

    for (let i = 0; i < 7; i++) {
        let day = {
            name: date.format('dd').substring(0, 1),
            number: date.date(),
            isCurrentMonth: date.month() === month.month(),
            isToday: date.isSame(new Date(), 'day'),
            date: date
        }

        days.push(
            <Day day={day}/>
        )
    
        date = date.clone();
        date.add(1, 'day');
    };

    return(
        <div className="row week" key={days[0]}>
            <div className="col-sm-12 d-flex justify-content-between text-center">
                {days}
            </div>
        </div>
    )
}

export default Week;