import React, {Component} from 'react';
import EventCreator from '../event-creator';

export default class Day extends Component {

    state = {
        showEventCreator: false,
        events: []
    }

    toggleEventCreatorStatus = () => {
        this.setState({
            showEventCreator: true
        })
    }

    closeEventCreator = (status) => {
        this.setState({
            showEventCreator: status
        })
    }

    render() {
        const {day: {date, isCurrentMonth, isToday, number}} = this.props;
        
        return (
            <div 
                className={"week-day" + (isToday ? " today" : "") + (isCurrentMonth ? "" : " different-month")}
                onClick={this.toggleEventCreatorStatus}
                key={date.toString()}>
                <span>
                    {number}
                </span>
                {
                    this.state.showEventCreator ? <EventCreator  closeEventCreator={this.closeEventCreator} /> : null
                }
            </div>
        )
    }
}