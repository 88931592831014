import React from 'react';
import './event-creator.css';

const EventCreator = (props) => {

    return (
        <form className="event-creator">
            <div className="input-field">
                <label>event name</label>
                <input type="text" name="name"/>
            </div>
            <div className="input-field input-field-date">
                <label>event date</label>
                <div className="relative">
                    <input type="text" name="date"/>
                    <i className="fa fa-calendar"></i>
                </div>
            </div>
            <div className="input-field input-field-time">
                <label>event time</label>
                <div className="relative">
                    <input type="text" name="time"/>
                    <i className="fa fa-clock-o"></i>
                </div>
            </div>
            <div className="input-field">
                <label>notes</label>
                <input type="text" name="notes"/>
            </div>
            <div className="group">
                <button className="cancel" type="button" onClick={() => props.closeEventCreator(false)}>Cancel</button>
                <button type="submit" className="save-btn">Save</button>
            </div>
        </form>
    )
}

export default EventCreator;