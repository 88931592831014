import React from 'react';
import './days-of-week.css';

const DaysOfWeek = () => {
    return (
        <div className="row">
            <div className="col-sm-12 d-flex justify-content-between days-header">
                <span className="box day-name">Sun</span>
                <span className="box day-name">Mon</span>
                <span className="box day-name">Tue</span>
                <span className="box day-name">Wed</span>
                <span className="box day-name">Thu</span>
                <span className="box day-name">Fri</span>
                <span className="box day-name">Sat</span>
            </div>
        </div>
    )
}

export default DaysOfWeek;