import React, {Component} from 'react';
import moment from 'moment';
import Header from '../header';
import Week from '../week';
import DaysOfWeek from '../days-of-week';
import './app.css';

export default class App extends Component {
    state = {
        selectedMonth: moment(),
        selectedDay: moment().startOf('day'),
        selectedMonthEvents: [],
        showEvents: false
    }

    previousMonth = () => {
        this.setState(({selectedMonth}) => {
            return {
                selectedMonth: selectedMonth.subtract(1, 'month')
            }
        })
    }

    nextMonth = () => {
        this.setState(({selectedMonth}) => {
            return {
                selectedMonth: selectedMonth.add(1, 'month')
            }
        })
    }

    select(day) {
        this.setState({
            selectedMonth: day.date,
            selectedDay: day.date.clone()
        })
    }

    currentMonth = () => {
        this.setState({
            selectedMonth: moment()
        })
    }

    renderWeeks() {
        const {selectedMonth: month} = this.state;

        let weeks = [];
        let done = false;
        let date = month.clone().startOf('month').add('w' - 1).day('Sunday');
        let count = 0;
        let monthIndex = date.month();

        while (!done) {
            weeks.push(
                <Week 
                    key={date}
                    date={date.clone()}
                    month={month}/>
            )

            date.add(1, 'w');

            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }

        return weeks;
    }


    render() {
        const {selectedMonth} = this.state;
        const currentMonthView = selectedMonth.format("MMMM YYYY");

        return (
            <div className="calendar-app">
                <div className="container">
                    <Header 
                        currentMonthView={currentMonthView} 
                        nextMonthHandler={this.nextMonth}
                        previousMonthHandler={this.previousMonth}
                        currentMonthHandler={this.currentMonth}/>
                    <DaysOfWeek />
                    {this.renderWeeks()}
                </div>
            </div>
        )
    }
} 