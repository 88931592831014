import React from 'react';
import './header.css';

const Header = (props) => {

    const {currentMonthView, nextMonthHandler, 
            previousMonthHandler, currentMonthHandler} = props;

    return (
        <div className="calendar-header">
            <div className="row">
                <div className="calendar-view">
                    <div className="col-sm-2">
                        <span>Calendar View</span>
                    </div>
                    <div className="col-sm-5 offset-sm-5 d-flex justify-content-end">
                        <div className="btn-group btn-group-toggle">
                            <button className="btn btn-outline-primary">Month</button>
                            <button className="btn btn-outline-primary">Week</button>
                            <button className="btn btn-outline-primary">Day</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="toggler-menu">
                    <div className="col-sm-5">
                        <div className="btn-group btn-group-toggle">
                            <button 
                                className="btn btn-outline-primary"
                                onClick={currentMonthHandler}>Today</button>
                            <button 
                                className="btn btn-outline-primary" 
                                onClick={previousMonthHandler}>Back</button>
                            <button 
                                className="btn btn-outline-primary" 
                                onClick={nextMonthHandler}>Next</button>
                        </div>
                    </div>
                   <div className="col-sm-2 text-center">
                        <span className="date">{currentMonthView}</span>
                   </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Header;